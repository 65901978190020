import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '../components/dashboard';
import { MaintenanceMessageAdmins } from '../components/maintenance-message';
import { RequireAuth } from '../components/require-auth';
import { usePermissions } from '../hooks/use-permissions';
import { AWS_GROUP } from '../types';
import { captureError } from '../utils/capture-error';
import { Account } from './account';
import { Appointments } from './appointments';
import { Contracts } from './contracts';
import { Documents } from './documents';
import { ErrorsOmissions } from './errors-omissions';
import { Help } from './help';
import { Home } from './home';
import { Licenses } from './licenses';
import { Requests } from './requests';
import { SignIn } from './sign-in';
import { Team } from './team';
import { Wallet } from './wallet';
import { WritingNumbers } from './writing-numbers';

const AppRoutes = {
  Admin: React.lazy(() => import('./admin')),
  SignUp: React.lazy(() => import('./sign-up')),
};

export function Root() {
  // Hooks
  const { checkPermissions } = usePermissions();

  // Query - Check for Maintenance
  const pathMaint = process.env.REACT_APP_MAINT_URL;
  const queryMaint = useQuery({
    enabled: pathMaint !== undefined,
    queryKey: [pathMaint],
    queryFn: async () => {
      if (pathMaint) {
        const body = { data: { origin: window.origin } };
        const response = await fetch(pathMaint, {
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        });
        const data: {
          data: {
            maintenanceActive: boolean;
            maintenanceBegin: string;
            maintenanceEnd: string;
          };
        } = await response.json();

        return data.data;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const isAdmin = checkPermissions([
    AWS_GROUP.ReadAll,
    AWS_GROUP.Contracting,
    AWS_GROUP.AgentDataAdmin,
    AWS_GROUP.ImpersonateAll,
  ]);

  // Agents cannot access the ARC during Maintenance
  // Admins can access the ARC during Maintentnace
  const displayAdminMaint =
    isAdmin &&
    queryMaint.data &&
    queryMaint.data.maintenanceActive &&
    queryMaint.data.maintenanceEnd;

  return (
    <React.Fragment>
      {displayAdminMaint && queryMaint.data ? (
        <MaintenanceMessageAdmins
          maintenanceEnd={queryMaint.data.maintenanceEnd}
        />
      ) : null}

      <Routes>
        <Route path="sign-in" element={<SignIn />} />
        <Route
          path="sign-up/:SignUpSlug"
          element={
            queryMaint.data && queryMaint.data.maintenanceActive ? (
              <Navigate to="/sign-in" replace />
            ) : (
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.SignUp />
              </React.Suspense>
            )
          }
        />

        <Route
          path="/"
          element={
            <RequireAuth isAdmin={isAdmin} maintData={queryMaint.data}>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route path="home" element={<Home />} />
          <Route
            path="admin/*"
            element={
              isAdmin ? (
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Admin />
                </React.Suspense>
              ) : (
                <Navigate to="home" />
              )
            }
          />
          <Route path="team" element={<Team />} />
          <Route path="account" element={<Account />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="licenses" element={<Licenses />} />
          <Route path="errors-omissions" element={<ErrorsOmissions />} />
          <Route path="documents" element={<Documents />} />
          <Route path="requests" element={<Requests />} />
          <Route path="contracts" element={<Contracts />} />
          <Route path="writing-numbers" element={<WritingNumbers />} />
          <Route path="appointments" element={<Appointments />} />
          <Route path="help" element={<Help />} />

          <Route path="/" element={<Navigate to="home" />} />
          <Route path="*" element={<Navigate to="home" />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}
