import { SupervisedUserCircle } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NumbersIcon from '@mui/icons-material/Numbers';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WalletIcon from '@mui/icons-material/Wallet';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/app-context';
import { AuthContext } from '../contexts/auth-context';
import { usePermissions } from '../hooks/use-permissions';
import { appTheme, materialTheme } from '../theme';
import { AWS_GROUP } from '../types';
import { SelectAgentImpersonate } from './select-agent-impersonate';

interface NavItem {
  name: string;
  icon: JSX.Element;
  path?: string;
  children?: NavItem[];
  // Only this Group can see this link
  hasGroup?: AWS_GROUP;
  // User must have one of the permissions
  permissions?: AWS_GROUP[];
}

const navLinks: NavItem[] = [
  {
    name: 'Home',
    icon: <HomeIcon color="inherit" />,
    path: '/home',
  },
  {
    name: 'Admin',
    icon: <AdminPanelSettingsIcon />,
    permissions: [
      AWS_GROUP.ReadAll,
      AWS_GROUP.Contracting,
      AWS_GROUP.AgentDataAdmin,
      AWS_GROUP.Staff_ReadOnly,
    ],
    children: [
      {
        name: 'Approvals',
        icon: <PendingActionsIcon />,
        path: '/admin/approvals',
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Contracting],
      },
      {
        name: 'Agent',
        icon: <ManageAccountsIcon />,
        path: '/admin/agent',
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.Contracting,
          AWS_GROUP.AgentDataAdmin,
          AWS_GROUP.Staff_ReadOnly,
        ],
      },
      {
        name: 'CMS',
        icon: <FormatColorTextIcon />,
        path: '/admin/cms',
        permissions: [AWS_GROUP.Contracting],
      },
      {
        name: 'Staff',
        icon: <SupervisedUserCircle />,
        path: '/admin/staff',
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.PermissionsAdmin],
      },
    ],
  },
  {
    name: 'Team Management',
    icon: <GroupsIcon />,
    path: '/team',
  },
  {
    name: 'Agent Profile',
    icon: <PersonIcon />,
    path: '/account',
  },
  {
    name: 'My Wallet',
    icon: <WalletIcon />,
    path: '/wallet',
  },
  {
    name: 'Licenses',
    icon: <GavelIcon />,
    path: '/licenses',
  },
  {
    name: 'E&O',
    icon: <ReportIcon />,
    path: '/errors-omissions',
  },
  {
    name: 'Documents',
    icon: <PermMediaIcon />,
    path: '/documents',
  },
  {
    name: 'Contracting Requests',
    icon: <NewReleasesIcon />,
    path: '/requests',
  },
  {
    name: 'My Contracts',
    icon: <SummarizeIcon />,
    path: '/contracts',
  },
  {
    name: "My Carrier Writing #'s",
    icon: <NumbersIcon />,
    path: '/writing-numbers',
  },
  {
    name: 'My Appointments',
    icon: <AssignmentIcon />,
    path: '/appointments',
  },
  // {
  //   name: 'Help and Support',
  //   icon: <ContactSupportIcon />,
  //   path: '/help',
  // },
];

function NavListItem(props: { item: NavItem; isActive: boolean }) {
  // Props
  const { item, isActive } = props;
  // State
  const [open, setOpen] = React.useState(false);
  // Hooks
  const location = useLocation();
  const { checkPermissions, hasGroup } = usePermissions();

  const handleClick = () => {
    setOpen((currentState) => !currentState);
  };

  let canView = true;
  if (item.hasGroup) {
    // User must have this specific group to view link
    canView = hasGroup(item.hasGroup);
  } else if (item.permissions) {
    // User must have one of the permissions
    canView = checkPermissions(item.permissions);
  }

  if (canView && item.children && item.children.length) {
    return (
      <React.Fragment>
        <ListItem disablePadding>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} disableTypography />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" dense disablePadding sx={{ pl: 4 }}>
            {item.children.map((navItem) => {
              const childActive = location.pathname === navItem.path;
              return (
                <NavListItem
                  key={navItem.name}
                  item={navItem}
                  isActive={childActive}
                />
              );
            })}
          </List>
        </Collapse>
      </React.Fragment>
    );
  } else if (canView && item.path) {
    return (
      <ListItem
        disablePadding
        sx={{
          transition: 'all 0.2s',
          backgroundColor: isActive ? '#00000011' : 'inherit',
          borderRightWidth: 4,
          borderRightStyle: 'solid',
          borderRightColor: isActive
            ? materialTheme.palette.secondary.main
            : 'transparent',
          ':hover': {
            borderRightColor: isActive
              ? materialTheme.palette.secondary.main
              : '#00000022',
          },
        }}
      >
        <ListItemButton component={Link} to={item.path}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.name}
            disableTypography
            sx={{ fontWeight: isActive ? 'bold' : 'inherit' }}
          />
        </ListItemButton>
      </ListItem>
    );
  } else {
    return null;
  }
}

export function Dashboard() {
  // Context
  const { state, dispatch } = React.useContext(AppContext);
  const authContext = React.useContext(AuthContext);
  // Hooks
  const location = useLocation();

  const drawer = (
    <Box
      sx={{
        backgroundColor: '#fffbf3',
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: materialTheme.palette.primary.main + 'ee',
          color: '#fff',
        }}
      >
        {authContext.state.authorize?.IMO.Logo ? (
          <Box
            component="img"
            src={authContext.state.authorize.IMO.Logo}
            alt={
              authContext.state.authorize?.IMO.IMODisplayName || 'Contracting'
            }
            sx={{ maxHeight: 40 }}
          />
        ) : authContext.state.authorize?.IMO.IMODisplayName ? (
          <Box sx={{ fontWeight: 'bold', fontSize: 14 }}>
            {authContext.state.authorize?.IMO.IMODisplayName}
          </Box>
        ) : (
          <Box sx={{ fontWeight: 'bold', fontSize: 18 }}>Contracting</Box>
        )}
      </Toolbar>

      <Box
        sx={{
          minHeight: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          borderRightColor: materialTheme.palette.primary.main + '11',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
        }}
      >
        <SelectAgentImpersonate />

        <Box
          sx={{
            minHeight: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <List dense sx={{ flex: 1 }}>
            {navLinks.map((navItem) => {
              const isActive = location.pathname === navItem.path;
              return (
                <NavListItem
                  key={navItem.name}
                  item={navItem}
                  isActive={isActive}
                />
              );
            })}
            <ListItem
              disablePadding
              sx={{
                transition: 'all 0.2s',
                backgroundColor: 'inherit',
                borderRightWidth: 4,
                borderRightStyle: 'solid',
                borderRightColor: 'transparent',
                ':hover': {
                  borderRightColor: '#00000022',
                },
              }}
            >
              <ListItemButton
                component="a"
                href="https://arc.naaleads.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <ArrowBackIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Back to ARC"
                  disableTypography
                  sx={{ fontWeight: 'inherit' }}
                />
              </ListItemButton>
            </ListItem>
          </List>

          <Box sx={{ p: 1 }}>
            <Box>
              <Box
                component="a"
                href="https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-terms-of-service.pdf"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  textDecorationLine: 'none',
                  fontSize: 12,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  transition: 'all 0.2s',
                  color: '#9b9b9b',
                  ':hover': {
                    color: '#2196f3',
                  },
                }}
              >
                Terms of Service
              </Box>
            </Box>

            <Box>
              <Box
                component="a"
                href="https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-privacy-notice.pdf"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  textDecorationLine: 'none',
                  fontSize: 12,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  transition: 'all 0.2s',
                  color: '#9b9b9b',
                  ':hover': {
                    color: '#2196f3',
                  },
                }}
              >
                Privacy Notice
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: appTheme.drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={window.document.body}
          variant="temporary"
          open={state.drawerOpen}
          onClose={() => dispatch({ type: 'TOGGLE_DRAWER' })}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: appTheme.drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              border: 'none',
              width: appTheme.drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Outlet />
    </Box>
  );
}
